import http from '@/api/http'

import {
  TCreaTeamResponse,
  TCreateTeam,
  TDeleteTeamParams,
  TDeleteTeamResponse,
  TGetMembersRolesResponse,
  TGetSupervisorResponse,
  TGetSupervisorsParams,
  TGetTeamResponse,
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamsParams,
  TGetTeamsResponse,
  TGetUserProfileResponse,
  TGetVolumeParams,
  TGetVolumesPaginatedParams,
  TGetVolumesPaginatedResponse,
  TGetVolumesResponse,
  TUpdateTeamAnalystParams,
  TUpdateTeamAnalystResponse,
} from './types'

export const getTeams = async () => {
  const response = await http.get<TGetTeamsResponse>('/team/')

  return response.data
}

export const getTeamsPaginated = async (filters: TGetTeamsPaginatedParams) => {
  const response = await http.get<TGetTeamsPaginatedResponse>('/team/', {
    params: filters,
  })

  return response.data
}

export const getTeam = async ({ coTeam }: TGetTeamsParams) => {
  const response = await http.get<TGetTeamResponse>(`/team/${coTeam}/`)

  return response.data
}

export const getMemberRoles = async () => {
  const response = await http.get<TGetMembersRolesResponse>('/team/user_roles/')

  return response.data
}

export const getUserProfiles = async () => {
  const response = await http.get<TGetUserProfileResponse>('/users/profile/')

  return response.data
}

export const getSupervisors = async ({ coUser }: TGetSupervisorsParams) => {
  const response = await http.get<TGetSupervisorResponse>(
    `/users/management/${coUser}/`
  )

  return response.data
}

export const getVolumeDistributions = async ({ coTeam }: TGetVolumeParams) => {
  const response = await http.get<TGetVolumesResponse>(
    `/team/team_object_report/${coTeam}/`
  )

  return response.data
}

export const getVolumesPaginated = async ({
  page = 1,
  pageSize = 10,
}: TGetVolumesPaginatedParams) => {
  const response = await http.get<TGetVolumesPaginatedResponse>(
    `/team/team_deed_distribution/?page=${page}&page_size=${pageSize}`
  )

  return response.data
}

export const postCreateTeam = async (team: TCreateTeam) => {
  const response = await http.post<TCreaTeamResponse>('/team/', team)

  return response.data
}

export const postDeleteTeam = async (
  params: TDeleteTeamParams
): Promise<TDeleteTeamResponse> => {
  const response = await http.post<TDeleteTeamResponse>('/team/', params)
}

export const postUpdateTeamAnalyst = async (
  params: TUpdateTeamAnalystParams
) => {
  const response = await http.post<TUpdateTeamAnalystResponse>(
    '/team/add_analyst/',
    params
  )

  return response.data
}

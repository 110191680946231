import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormikValues } from 'formik'

import { useToast, useDisclosure } from '@chakra-ui/react'

import {
  useQueryGetCities,
  useQueryGetCountries,
  useQueryGetStates,
} from '@/api/address/queries'
import {
  useQueryGetEducationLevels,
  useQueryGetSpecialNeeds,
} from '@/api/user/auxiliaries/queries'
import { useMutationUpdateMyAccount } from '@/api/user/management/mutations'
import { useQueryMe, useQueryMyAccount } from '@/api/user/management/queries'
import { TUpdateMyAccountParams } from '@/api/user/management/types'
import { ITabInfo } from '@/components/Tabs/types'
import { parseImgToBase64 } from '@/utils/parser'

export const useProfileController = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [selectedState, setSelectedState] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  const [selectedCity, setSelectedCity] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: userData, isLoading: isLoadingMe } = useQueryMe({
    onError: () => {
      toast({
        title: 'Erro ao buscar usuário',
        description: 'Ocorreu um erro ao buscar o usuário.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  const {
    data: dataMyAccount,
    isLoading: isLoadingMyAccount,
    refetch: refectDataMyAccount,
  } = useQueryMyAccount(
    {
      coUser: `${userData?.coUser}`,
    },
    {
      enabled: !!userData,
      onError: () => {
        toast({
          title: 'Erro ao buscar usuário',
          description: 'Ocorreu um erro ao buscar o usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const { data: specialNeeds } = useQueryGetSpecialNeeds()

  const { data: educationLevel } = useQueryGetEducationLevels()

  const { data: rawCountries = [], isLoading: isLoadingCountries } =
    useQueryGetCountries({
      onError: () => {
        toast({
          title: 'Erro ao buscar países',
          description: 'Ocorreu um erro ao buscar os países.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    })

  const { data: rawStates = [], isLoading: isLoadingStates } =
    useQueryGetStates(
      {
        country: selectedCountry,
      },
      {
        enabled: !!selectedCountry,
        onError: () => {
          toast({
            title: 'Erro ao buscar estados',
            description: 'Ocorreu um erro ao buscar os estados.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      }
    )

  const { data: rawCities = [], isLoading: isLoadingCities } =
    useQueryGetCities(
      {
        uf: selectedState,
        country: selectedCountry,
      },
      {
        enabled: !!selectedState && !!selectedCountry,
        onError: () => {
          toast({
            title: 'Erro ao buscar cidades',
            description: 'Ocorreu um erro ao buscar as cidades.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      }
    )

  const { mutate: updateMyAccount, isLoading: isUpdatingAccount } =
    useMutationUpdateMyAccount({
      onSuccess: () => {
        toast({
          title: 'Dados atualizados',
          description: 'Seus dados foram atualizados com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        refectDataMyAccount()
        toast.close('updating-photo')
      },
      onError: () => {
        toast({
          title: 'Erro ao atualizar dados',
          description: 'Ocorreu um erro ao atualizar seus dados.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })

  const isLoadingUserData = isLoadingMe || isLoadingMyAccount

  const countries = useMemo(() => {
    return Array.from(new Set(rawCountries)).map(country => {
      return {
        value: country,
        label: country,
      }
    })
  }, [rawCountries])

  const states = useMemo(() => {
    return Array.from(new Set(rawStates)).map(state => {
      return {
        value: state,
        label: state,
      }
    })
  }, [rawStates])

  const cities = useMemo(() => {
    return Array.from(new Set(rawCities)).map(city => {
      return {
        value: city,
        label: city,
      }
    })
  }, [rawCities])

  const userValues = useMemo(() => {
    setSelectedCountry(dataMyAccount?.dsCountry || '')
    setSelectedState(dataMyAccount?.dsUfProvince || '')
    setSelectedCity(dataMyAccount?.dsCity || '')

    return {
      coUser: userData?.coUser || '',
      noUserName: dataMyAccount?.noUserName || '',
      noUserSocialName: dataMyAccount?.noUserSocialName || '',
      dsCpf: dataMyAccount?.dsCpf || '',
      dtBirthDate: dataMyAccount?.dtBirthDate || '',
      coSpecialNeed: dataMyAccount?.coSpecialNeed || '',
      coEducationLevel: dataMyAccount?.coEducationLevel || '',
      dsInstitution: dataMyAccount?.dsInstitution || '',
      dsInstitutionalEmail: dataMyAccount?.dsInstitutionalEmail || '',
      dsRolePositionJob: dataMyAccount?.dsRolePositionJob || '',
      dsGovbrEmail: userData?.dsGovbrEmail || '',
      dsCellphone: dataMyAccount?.dsCellphone || '',
      dsCountry: dataMyAccount?.dsCountry || '',
      dsUfProvince: dataMyAccount?.dsUfProvince || '',
      dsCity: dataMyAccount?.dsCity || '',
      dtJobBindStart: dataMyAccount?.dtJobBindStart || '',
      dtJobBindEnd: dataMyAccount?.dtJobBindEnd || '',
      dsPhotoUrl: dataMyAccount?.dsPhotoUrl || '',
    }
  }, [userData, dataMyAccount])

  const specialNeedsOptions = useMemo(() => {
    return specialNeeds?.map(need => ({
      value: need.coSpecialNeed,
      label: need.dsSpecialNeed,
    }))
  }, [specialNeeds])

  const educationLevelsOptions = useMemo(() => {
    return educationLevel?.map(level => ({
      value: level.coEducationLevel,
      label: level.dsEducationLevel,
    }))
  }, [educationLevel])

  const isStateSelectDisabled = !selectedCountry
  const isCitySelectDisabled = !selectedState

  const handleTabClick = ({ id }: ITabInfo) => {
    navigate(`${id}`)
  }

  const handleSubmit = (values: FormikValues) => {
    const payload = {
      ...values,
      coSpecialNeed: values.coSpecialNeed ? Number(values.coSpecialNeed) : '',
      coEducationLevel: values.coEducationLevel
        ? Number(values.coEducationLevel)
        : '',
    }

    updateMyAccount(payload as TUpdateMyAccountParams)
  }

  const handleRemovePicture = () => {
    const payload = {
      coUser: userValues.coUser,
      dsPhotoUrl: '',
    }

    updateMyAccount(payload as TUpdateMyAccountParams)
  }

  const handlePhotoChange = async (values: FormikValues) => {
    const base64 = await parseImgToBase64(values.file)

    const payload = {
      coUser: userValues.coUser,
      dsPhotoUrl: base64.split(',')[1],
    }

    updateMyAccount(payload as TUpdateMyAccountParams)
  }

  const updatingPhotoToast = () => {
    toast({
      id: 'updating-photo',
      title: 'Atualizando foto',
      description: 'Estamos atualizando sua foto, aguarde um momento.',
      status: 'info',
      duration: null,
      isClosable: true,
    })
  }

  return {
    userData,
    isLoadingUserData,
    dataMyAccount,
    userValues,
    updateMyAccount,
    isUpdatingAccount,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    isStateSelectDisabled,
    isCitySelectDisabled,
    isOpen,
    onOpen,
    onClose,
    handleTabClick,
    handleSubmit,
    handleRemovePicture,
    handlePhotoChange,
    updatingPhotoToast,
  }
}

import http from '@/api/http'

import { TGetObjectsParams, TGetObjectsResponse } from './types'

export const getObjects = async (filters: TGetObjectsParams) => {
  const response = await http.get<TGetObjectsResponse>('/objects/', {
    params: filters,
  })

  return response.data
}

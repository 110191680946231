import React from 'react'

import { Form, Formik } from 'formik'

import {
  Text,
  Button,
  Flex,
  Stack,
  useDisclosure,
  Divider,
} from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import Modal from '@/components/Modal'

import { IModalProps } from '../types'

export const ModalExistingQuestion = ({
  isOpen,
  onClose,
}: IModalProps): JSX.Element => {
  const volumeFormatOptions = [
    {
      value: '1',
      label: 'Formato 01',
    },
    {
      value: '2',
      label: 'Formato 02',
    },
  ]

  const volumeVersionOptions = [
    {
      value: '1',
      label: 'Versão 01',
    },
    {
      value: '2',
      label: 'Versão 02',
    },
  ]

  const volumeExamepleOptions = [
    {
      value: '1',
      label: 'Exemplar 01',
    },
    {
      value: '2',
      label: 'Exemplar 02',
    },
  ]

  const handleAddQuestion = () => {
    // TODO: Implementar a lógica de adicionar pergunta
    onClose()
  }

  return (
    <Modal
      title="Adicionar pergunta existente"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <Input name="coQuestion" label="Pesquisar" />
            <Flex gap={4}>
              <Select
                name="coResponseType"
                label="Tipo de resposta"
                placeholder="--"
                options={volumeVersionOptions}
              />
              <Select
                name="coFailure"
                label="Código da Falha"
                placeholder="--"
                options={volumeExamepleOptions}
              />
            </Flex>
            <Flex gap={4}>
              <Select
                name="coVolumeFormat"
                label="Formato do Volume"
                placeholder="--"
                options={volumeFormatOptions}
              />
              <Select
                name="coVolumeVersion"
                label="Versao do Volume"
                placeholder="--"
                options={volumeVersionOptions}
              />
              <Select
                name="coVolumeExameple"
                label="Exemplar do Volume"
                placeholder="--"
                options={volumeExamepleOptions}
              />
            </Flex>
            <Button width="100%">Buscar</Button>
          </Form>
        </Formik>
        <Divider />
        <Flex
          justifyContent="right"
          gap="12px"
          alignItems="center"
          flexDirection="row"
        >
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Cancelar
          </Button>
          <Button borderRadius="full" onClick={handleAddQuestion}>
            Adicionar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

import React, { useState } from 'react'

import { Box } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

import ContentEmpityTab from '../../ContentEmpityTab'
import {
  filtersTableForms,
  tableDataForms,
  tableHeadersForms,
} from './DataTableForms'

const FormsTab: React.FC = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 20,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  return (
    <>
      {!tableDataForms ? (
        <ContentEmpityTab />
      ) : (
        <>
          <DataFilter
            testId="data-filter"
            canDownload={false}
            canPrint={false}
            filters={filtersTableForms}
            onChangeFilter={() => {}}
          />
          <Box mt={10}>
            <DataTable headers={tableHeadersForms} data={tableDataForms} />
            <Pagination
              pageIndex={pagination.page - 1}
              optionsItemsPerPage={pagination.pageSizeOptions}
              itemsPerPage={pagination.pageSize}
              pageCount={pagination.pageCount}
              itemsCount={pagination.itemsCount}
              onChangePageInfo={handleOnChangePageInfo}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default FormsTab

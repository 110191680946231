import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  getUser,
  getUsers,
  getUsersPaginated,
  getMe,
  getMyAccount,
  getProfileNames,
  getUserTeams,
} from './services'
import {
  TGetUserParams,
  TGetUserResponse,
  TGetUsersResponse,
  TGetUsersPaginatedParams,
  TGetUsersPaginatedResponse,
  TGetMeResponse,
  TGetUserProfileNameResponses,
  TGetUserTeamsPaginatedParams,
  TGetUserTeamsPaginatedResponse,
} from './types'

export const useQueryUsers = (options?: UseQueryOptions<TGetUsersResponse>) => {
  const queryFunction = () => getUsers()

  return useQuery<TGetUsersResponse>(
    useQueryUsers.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUsers.getFullQueryKey = (): QueryKey => ['users']

useQueryUsers.queryKey = ['users']

export const useQueryUsersPaginated = (
  input: TGetUsersPaginatedParams,
  options?: UseQueryOptions<TGetUsersPaginatedResponse>
) => {
  const queryFunction = () => getUsersPaginated(input)

  return useQuery<TGetUsersPaginatedResponse>(
    useQueryUsersPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryUsersPaginated.getFullQueryKey = (
  input: TGetUsersPaginatedParams
): QueryKey => ['users_paginated', input]

useQueryUsersPaginated.queryKey = (
  input: TGetUsersPaginatedParams
): QueryKey => ['users_paginated', input]

export const useQueryUser = (
  input: TGetUserParams,
  options?: UseQueryOptions<TGetUserResponse>
) => {
  const queryFunction = () => getUser(input)

  return useQuery<TGetUserResponse>(
    useQueryUser.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUser.getFullQueryKey = (): QueryKey => ['User']

useQueryUser.queryKey = ['User']

export const useQueryProfileNames = (
  options?: UseQueryOptions<TGetUserProfileNameResponses>
) => {
  const queryFunction = () => getProfileNames()

  return useQuery<TGetUserProfileNameResponses>(
    useQueryProfileNames.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryProfileNames.getFullQueryKey = (): QueryKey => ['profileNames']

useQueryProfileNames.queryKey = ['profileNames']

export const useQueryMe = (options?: UseQueryOptions<TGetMeResponse>) => {
  const queryFunction = () => getMe()

  return useQuery<TGetMeResponse>(
    useQueryMe.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryMe.getFullQueryKey = (): QueryKey => ['me']

useQueryMe.queryKey = ['me']

export const useQueryMyAccount = (
  input: TGetUserParams,
  options?: UseQueryOptions<TGetUserResponse>
) => {
  const queryFunction = () => getMyAccount(input)
  return useQuery<TGetUserResponse>(
    useQueryMyAccount.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryMyAccount.getFullQueryKey = (input: TGetUserParams): QueryKey => [
  'myaccount',
  input,
]
useQueryMyAccount.queryKey = (input: TGetUserParams): QueryKey => [
  'myaccount',
  input,
]

export const useQueryUserTeams = (
  input: TGetUserTeamsPaginatedParams,
  options?: UseQueryOptions<TGetUserTeamsPaginatedResponse>
) => {
  const queryFunction = () => getUserTeams(input)

  return useQuery<TGetUserTeamsPaginatedResponse>(
    useQueryUserTeams.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryUserTeams.getFullQueryKey = (
  input: TGetUserTeamsPaginatedParams
): QueryKey => ['userTeams', input]

useQueryUserTeams.queryKey = (
  input: TGetUserTeamsPaginatedParams
): QueryKey => ['userTeams', input]

import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import {
  postCreateTeam,
  postDeleteTeam,
  postUpdateTeamAnalyst,
} from './services'
import {
  TCreaTeamResponse,
  TCreateTeam,
  TDeleteTeamParams,
  TDeleteTeamResponse,
  TUpdateTeamAnalystParams,
  TUpdateTeamAnalystResponse,
} from './types'

export const useMutationCreateTeam = (
  options?: UseMutationOptions<TCreaTeamResponse, IErrorDTO, TCreateTeam>
) => {
  return useMutation(postCreateTeam, options)
}

export const useMutationDeleteTeams = (
  options?: UseMutationOptions<
    TDeleteTeamResponse,
    AxiosError<IErrorDTO>,
    TDeleteTeamParams
  >
) => {
  return useMutation(postDeleteTeam, options)
}

export const useMutationUpdateTeamAnalyst = (
  options?: UseMutationOptions<
    TUpdateTeamAnalystResponse,
    AxiosError<IErrorDTO>,
    TUpdateTeamAnalystParams
  >
) => {
  return useMutation(postUpdateTeamAnalyst, options)
}

import moment from 'moment'

/**
 * @description Function to format a timestamp into a specified date format.
 * @param {string} timestamp - The timestamp to format.
 * @param {string} [format='DD/MM/YYYY'] - The format to apply to the date. Defaults to 'DD/MM/YYYY'.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (timestamp: string, format = 'DD/MM/YYYY') => {
  if (!timestamp) return ''
  const date = moment(timestamp)

  return date.format(format)
}

/**
 * @description Function to reverse the format of a date from 'DD/MM/YYYY' to 'YYYY-MM-DD'.
 * @param {string} date - The date string in 'DD/MM/YYYY' format.
 * @returns {string} - The date string in 'YYYY-MM-DD' format.
 */
export const reverseDate = (date: string) => {
  if (!date) return ''
  const dateArray = date.split('/')
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
}

import React from 'react'
import { FaCircleExclamation, FaCirclePlay } from 'react-icons/fa6'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

interface PhaseNotStartedProps {
  phaseName: string
  IsNextPhase?: boolean
}

const PhaseNotStarted: React.FC<PhaseNotStartedProps> = ({
  phaseName,
  IsNextPhase = false,
}) => {
  return (
    <Flex direction="column">
      <Flex direction="row" justify="space-between">
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          {phaseName}
        </Text>
        <Button
          bgColor="#168821"
          borderRadius="4"
          leftIcon={<Icon as={FaCirclePlay} />}
          isDisabled={!IsNextPhase}
          _hover={{ bgColor: '#026E1F' }}
        >
          Iniciar Fase
        </Button>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        gap={6}
        padding="60px"
      >
        <Icon as={FaCircleExclamation} boxSize="128px" color="#475671" />
        <Box w="50%">
          <Text
            color="brand.primary.dark_2"
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Fase ainda não iniciada!
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default PhaseNotStarted

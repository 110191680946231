import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  IconButton,
  VStack,
  Text,
  Stack,
  useToast,
} from '@chakra-ui/react'

import { useQueryVolume, useQueryVolumesPaginated } from '@/api/teams/queries'
import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusVolumeTeam'

import { analystsFilterExample } from '../../exampleData'
import { volumeTableHeaders, volumeToTableExample } from './exampleData'

const volumeVolumeTable = ({ teamId }: { teamId: number | undefined }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const navigate = useNavigate()
  const toast = useToast()

  const pageSizeOptions = [10, 20, 30, 40, 50]
  const itemsPerPage = 5
  const totalvolumes = volumeToTableExample.length
  const totalPages = Math.ceil(totalvolumes / itemsPerPage)

  const handleOnChangePageInfo = ({ pageIndex }: IPageInfoProps) => {
    setPageIndex(pageIndex)
  }

  const volumesDataTable = useMemo(() => {
    const volumes = volumeToTableExample ? volumeToTableExample : []

    const mappedVolumes = volumes.map(volume => {
      const volumeInfo = (
        name: string,
        editora: string,
        colecao: string,
        versao: string
      ) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {name}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {editora} | {colecao} | {versao}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          navigate(`/configuracoes/equipes/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            height="unset"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      return {
        volume: volumeInfo(
          volume.name,
          volume.editora,
          volume.colecao,
          volume.versao
        ),
        analist1: volume.analyst[0].label,
        analist2: volume.analyst[1].label,
        status: StatusTag(volume.status),
        edit: editButton(volume.id as string),
        status_code: volume.status,
      }
    })
    return mappedVolumes
  }, [volumeToTableExample])

  return (
    <>
      <Stack gap={4}>
        <DataFilter
          testId="teams-filter"
          canPrint={false}
          canDownload={false}
          onlySearch={true}
          filters={analystsFilterExample}
          onChangeFilter={() => {}}
        />
        <DataTable
          rowId="cod_volume_volume"
          headers={volumeTableHeaders}
          data={volumesDataTable}
          isLoading={false}
        />
        <Pagination
          pageIndex={pageIndex}
          optionsItemsPerPage={pageSizeOptions}
          itemsPerPage={itemsPerPage}
          pageCount={totalPages}
          itemsCount={totalvolumes}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Stack>
    </>
  )
}
export default volumeVolumeTable

import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex, Stack, Text, Button } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'

import Data from './Data'

// TODO: Analisar a questão das tabs
const Checklist = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'formulario',
      label: 'Formulário',
    },
    // {
    //   id: 'diligencias',
    //   label: 'Diligências',
    // },
    {
      id: 'perguntas',
      label: 'Perguntas',
    },
    {
      id: 'falhas',
      label: 'Falhas',
    },
  ]

  const handleTabClick = (id: string) => {
    navigate(`/formularios/${id}`)
  }

  const handleCreateForm = () => {
    navigate('/formularios/cadastrar-formulario')
  }

  const checkListTabs: Array<ITabItem> = [
    {
      id: 'waiting_validation',
      label: 'Aguardando validação',
      render: () => <Data />,
    },
    {
      id: 'waiting_link',
      label: 'Aguardando vínculo',
      render: () => <Data />,
    },
    {
      id: 'linked',
      label: 'Vinculados',
      render: () => <Data />,
    },
    {
      id: 'finished',
      label: 'Finalizados',
      render: () => <Data />,
    },
  ]

  return (
    <>
      {/* <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => handleTabClick(id)}
      /> */}
      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
            Lista de formulários
          </Text>
          <Button
            leftIcon={<FaPlusCircle />}
            onClick={handleCreateForm}
            borderRadius="full"
            size="sm"
          >
            Cadastrar novo formulário
          </Button>
        </Flex>
        <Tabs variant="rounded" items={checkListTabs} initialTab="principal" />
      </Stack>
    </>
  )
}

export default Checklist

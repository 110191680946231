import React, { useEffect, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import { Flex, IconButton, Text } from '@chakra-ui/react'

import Select from '@/components/Select'
import { IOptionProps } from '@/components/Select/types'

import S from './styles'
import { IPaginationProps } from './types'

const Pagination: React.FC<IPaginationProps> = ({
  optionsItemsPerPage,
  itemsPerPage = 5,
  pageIndex = 0,
  itemsCount,
  pageCount,
  onChangePageInfo,
}) => {
  const [selectedItemsPerPage, setSelectedItemsPerPage] =
    useState<IOptionProps>({
      label: String(itemsPerPage),
      value: itemsPerPage,
    })
  const [selectedPage, setSelectedPage] = useState<IOptionProps>({
    label: String(pageIndex + 1),
    value: pageIndex,
  })

  const optionsItems = optionsItemsPerPage?.map(item => {
    return {
      label: String(item),
      value: item,
    } as IOptionProps
  })

  const optionsPage: IOptionProps[] = Array.from({ length: pageCount }).map(
    (_, index) => {
      return {
        label: String(index + 1),
        value: index,
      } as IOptionProps
    }
  )

  const disablePrevious = Number(selectedPage.value) === 0
  const disableNext = Number(selectedPage.value) >= pageCount - 1

  useEffect(() => {
    const pageIndex = Number(selectedPage.value)
    const itemsPerPage = Number(selectedItemsPerPage.value)

    onChangePageInfo?.({
      pageIndex,
      itemsPerPage,
    })
  }, [selectedPage, selectedItemsPerPage])

  useEffect(() => {
    setSelectedPage({ label: String(pageIndex + 1), value: pageIndex })
  }, [pageIndex])

  const goToPreviousPage = () => {
    const page = Number(selectedPage.value)
    setSelectedPage({ label: String(page), value: page - 1 })
  }
  const goToNextPage = () => {
    const page = Number(selectedPage.value)
    setSelectedPage({ label: String(page + 2), value: page + 1 })
  }

  return (
    <>
      <S.Pagination>
        {optionsItemsPerPage ? (
          <Flex alignItems="center" gap={4}>
            <Text fontSize="sm" fontWeight="400">
              Exibir
            </Text>
            <Select
              variant="filled"
              value={selectedItemsPerPage}
              options={optionsItems}
              placeholder="Items por página"
              isSearchable={false}
              menuPlacement="auto"
              onChange={option => {
                setSelectedItemsPerPage(option as IOptionProps)
              }}
            />
            <S.Divider orientation="vertical" />
            <Text fontSize="sm" fontWeight="400" whiteSpace="nowrap">
              {Number(selectedItemsPerPage.value) *
                (Number(selectedPage.value) + 1) -
                Number(selectedItemsPerPage.value) +
                1}{' '}
              -{' '}
              {Number(selectedItemsPerPage.value) *
                (Number(selectedPage.value) + 1)}{' '}
              de {itemsCount} itens
            </Text>
          </Flex>
        ) : (
          <Flex />
        )}
        <Flex alignItems="center">
          <S.PageCounter>
            <Text fontSize="sm" fontWeight="400">
              Página {pageIndex + 1}
            </Text>
          </S.PageCounter>
          <S.ItemsCounter>
            <Text fontSize="sm" fontWeight="400">
              Página
            </Text>
            <Select
              variant="filled"
              value={selectedPage}
              options={optionsPage}
              isSearchable={false}
              menuPlacement="auto"
              onChange={option => {
                setSelectedPage(option as IOptionProps)
              }}
            />
            <S.Divider orientation="vertical" />
          </S.ItemsCounter>
          <IconButton
            icon={<FaAngleLeft />}
            color="brand.primary.dark_1"
            onClick={goToPreviousPage}
            isDisabled={disablePrevious}
            variant="ghost"
            aria-label="Ir para a página anterior"
          />
          <IconButton
            icon={<FaAngleRight />}
            color="brand.primary.dark_1"
            onClick={goToNextPage}
            isDisabled={disableNext}
            variant="ghost"
            aria-label="Ir para a próxima página"
          />
        </Flex>
      </S.Pagination>
    </>
  )
}

export default Pagination

import React from 'react'
import { FaChevronLeft, FaCircleChevronRight } from 'react-icons/fa6'

import FileUpload from 'components/FileUpload'
import { Formik, Form, Field } from 'formik'

import { Box, Flex, Icon, Text, Button, useToast } from '@chakra-ui/react'

import { parseCSV } from '@/utils/parser'

import { ManualImportProps, IObjectFileData } from './types'

const ManualImport = ({
  activeStep,
  setActiveStep,
  setData,
}: ManualImportProps) => {
  const toast = useToast()
  const requiredColumns = [
    'edital',
    'object_id',
    'objeto',
    'editora',
    'código_coleção',
    'título_coleção',
    'código_volume',
    'formato',
    'exemplar',
    'versao',
  ]

  const handleSubmit = async (file: File | null) => {
    try {
      if (!file) {
        toast({
          title: 'Erro ao enviar arquivo',
          description: 'Selecione um arquivo para continuar',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      const data = await parseCSV({ file, requiredColumns: requiredColumns })
      const mappedData: IObjectFileData[] = data.map(row => {
        return row as IObjectFileData
      })

      setData(mappedData)
      setActiveStep(activeStep + 1)
    } catch (error) {
      let errorMessage =
        'Erro ao enviar arquivo, analise o formato e as colunas do arquivo'

      if (error instanceof Error) {
        errorMessage = error.message
      }

      toast({
        title: 'Erro ao enviar arquivo',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      w="full"
      h="full"
    >
      <Formik
        initialValues={{ file: null }}
        onSubmit={values => {
          handleSubmit(values.file)
        }}
      >
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            flex="1"
            maxW="400px"
            w="full"
            gap="8"
            py="60px"
          >
            <Text
              color="brand.primary.dark_1"
              textAlign="center"
              fontSize="20px"
              fontWeight="700"
              lineHeight="4"
            >
              Envie o arquivo do objeto
            </Text>

            <Text fontSize="12px" fontWeight="400" lineHeight="3">
              Envie o arquivo do objeto exportado do PLND Digital. Lembre-se: O
              arquivo só poderá ser enviado no padrão do FNDE em .csv.
            </Text>
            <Box w="full">
              <Text fontSize="14px" fontWeight="600" lineHeight="6">
                Envio de arquivos
              </Text>
              <Field name="file">
                {() => (
                  <FileUpload
                    id="file"
                    name="file"
                    helperText="Arquivo(s) em .csv"
                    acceptedFileTypes={['.csv']}
                    maxFileSize={15}
                    isMultiple={false}
                  />
                )}
              </Field>
            </Box>
          </Flex>
          <Flex direction="row" justify="space-between" w="full">
            <Button
              type="button"
              color="brand.primary.dark_1"
              bg="white"
              _hover={{ bg: 'brand.primary.dark_2' }}
              gap="2"
              py="2"
              px="6"
              borderRadius="4"
              leftIcon={<Icon as={FaChevronLeft} />}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Voltar
            </Button>

            <Button
              type="submit"
              color="white"
              bg="brand.primary.dark_1"
              _hover={{ bg: 'brand.primary.dark_2' }}
              gap="2"
              py="2"
              px="6"
              borderRadius="4"
              leftIcon={<Icon as={FaCircleChevronRight} />}
            >
              Próximo
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Flex>
  )
}

export default ManualImport

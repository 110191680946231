import React from 'react'

import { Flex } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

const filters: Array<IFilter> = [
  {
    name: 'object',
    label: 'Objeto',
    type: 'text',
  },
  {
    name: 'permission',
    label: 'Permissão',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'Ativo', value: 'true' },
      { label: 'Finalizado', value: 'false' },
    ],
  },
]

const Teams = () => {
  const {
    userTeamsTableData,
    isLoadingUserTeams,
    pagination,
    handleOnChangePageInfoUserTeams: handleOnChangePageInfo,
    handleFilters,
  } = useProfileContext()

  const header: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'team',
    },
    {
      name: 'OBJETO',
      key: 'object',
    },
    {
      name: 'FASE',
      key: 'phase',
    },
    {
      name: 'PERMISSÃO',
      key: 'permission',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '10%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  return (
    <Flex direction="column" gap={4}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
      >
        <DataFilter
          testId="data-filter"
          filters={filters}
          onlySearch
          canDownload={false}
          canPrint={false}
          onChangeFilter={handleFilters}
        />
      </Flex>
      <DataTable
        headers={header}
        data={userTeamsTableData}
        isLoading={isLoadingUserTeams}
      />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Flex>
  )
}

export default Teams

import React from 'react'

import { Button, Flex, Stack, Text } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import { IModalProps } from '../types'

export const ModalDeleteForm = ({
  isOpen,
  onClose,
}: IModalProps): JSX.Element => {
  const handleDeleteQuestion = () => {
    //TODO: Implementar a lógica de aprovação
    onClose()
  }

  return (
    <Modal
      title="Excluir formulário"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Text fontSize="sm" color="brand.primary.dark_2">
          <strong>Esta ação é irreversível!</strong> Tem certeza que deseja
          excluir esse formulário permanentemente?
        </Text>
        <Flex
          justifyContent="right"
          gap="12px"
          alignItems="center"
          flexDirection="row"
        >
          <Button
            variant="error"
            borderRadius="full"
            onClick={handleDeleteQuestion}
          >
            Excluir formulário
          </Button>
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Cancelar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

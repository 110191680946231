import React, { useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaPlusCircle, FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Form, Formik } from 'formik'

import {
  Flex,
  Button,
  Text,
  Stack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

import { Select } from '@/components/Forms'

import { ModalQuestion } from './AddQuestionModal'
import { EmptyQuestions } from './EmptyQuestion'
import { QuestionsList } from './QuestionsList'
import { IQuestionItem } from './types'

const CreateForm = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState<IQuestionItem[]>([])

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onCloseModal,
  } = useDisclosure()

  const objectsOptions = [
    {
      value: '1',
      label: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '2',
      label: 'Objeto 02: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '3',
      label: 'Objeto 03: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '4',
      label: 'Objeto 04: Obras Didáticas - Impresso e Digital-Interativo',
    },
  ]

  const phaseOptions = [
    {
      value: '1',
      label: 'Em Andamento',
    },
    {
      value: '2',
      label: 'Concluído',
    },
  ]

  const failureGroupOptions = [
    {
      value: '1',
      label: 'Grupo 01',
    },
    {
      value: '2',
      label: 'Grupo 02',
    },
  ]

  const volumeFormatOptions = [
    {
      value: '1',
      label: 'Formato 01',
    },
    {
      value: '2',
      label: 'Formato 02',
    },
  ]

  const volumeVersionOptions = [
    {
      value: '1',
      label: 'Versão 01',
    },
    {
      value: '2',
      label: 'Versão 02',
    },
  ]

  const volumeExamepleOptions = [
    {
      value: '1',
      label: 'Exemplar 01',
    },
    {
      value: '2',
      label: 'Exemplar 02',
    },
  ]

  const handleAddQuestion = () => {
    onModalOpen()
  }

  const handleAddQuestionItem = (question: IQuestionItem) => {
    //TODO: Implement the add question item
  }

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Cadastrar novos formulários
          </Text>
        </Flex>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <Flex gap={4}>
              <Select
                name="coObjects"
                label="Objeto"
                placeholder="Selecione uma opção"
                options={objectsOptions}
              />
              <Select
                name="coPhase"
                label="Fase"
                placeholder="Selecione uma opção"
                options={phaseOptions}
              />
              <Select
                name="coFailureGroup"
                label="Grupo de falha"
                placeholder="Selecione uma opção"
                options={failureGroupOptions}
              />
            </Flex>
            <Flex gap={4}>
              <Select
                name="coVolumeFormat"
                label="Formato do Volume"
                placeholder="Selecione uma opção"
                options={volumeFormatOptions}
              />
              <Select
                name="coVolumeVersion"
                label="Versao do Volume"
                placeholder="Selecione uma opção"
                options={volumeVersionOptions}
              />
              <Select
                name="coVolumeExameple"
                label="Exemplar do Volume"
                placeholder="Selecione uma opção"
                options={volumeExamepleOptions}
              />
            </Flex>
          </Form>
        </Formik>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Perguntas
          </Text>
        </Flex>
        {questions.length === 0 ? <EmptyQuestions /> : <QuestionsList />}
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          padding="5"
          gap="8"
        >
          <Button
            size="sm"
            leftIcon={<BsChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
            onClick={() => navigate('/formularios/')}
          >
            Voltar
          </Button>
          <Flex gap={2}>
            <Button
              variant="outline"
              leftIcon={<FaPlusCircle />}
              onClick={handleAddQuestion}
            >
              Adicionar Pergunta
            </Button>
            <Button
              leftIcon={<FaSave />}
              variant={questions.length === 0 ? 'disabled' : 'solid'}
              disabled
            >
              Cadastrar Formulário
            </Button>
          </Flex>
        </Flex>
      </Stack>
      <ModalQuestion isOpen={isModalOpen} onClose={onCloseModal} />
    </>
  )
}

export default CreateForm

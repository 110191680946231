import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  getMemberRoles,
  getSupervisors,
  getTeam,
  getTeams,
  getTeamsPaginated,
  getUserProfiles,
  getVolumeDistributions,
  getVolumesPaginated,
} from './services'
import {
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamsResponse,
  TGetTeamsParams,
  TGetTeamResponse,
  TGetMembersRolesResponse,
  TGetSupervisorResponse,
  TGetVolumeParams,
  TGetVolumesResponse,
  TGetUserProfileResponse,
  TGetVolumesPaginatedResponse,
  TGetVolumesPaginatedParams,
} from './types'

export const useQueryTeams = (options?: UseQueryOptions<TGetTeamsResponse>) => {
  const queryFunction = () => getTeams()

  return useQuery<TGetTeamsResponse>(
    useQueryTeams.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeams.getFullQueryKey = (): QueryKey => ['teams']

useQueryTeams.queryKey = ['teams']

export const useQueryTeamsPaginated = (
  input: TGetTeamsPaginatedParams,
  options?: UseQueryOptions<TGetTeamsPaginatedResponse>
) => {
  const queryFunction = () => getTeamsPaginated(input)

  return useQuery<TGetTeamsPaginatedResponse>(
    useQueryTeamsPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryTeamsPaginated.getFullQueryKey = (
  input: TGetTeamsPaginatedParams
): QueryKey => ['teams_paginated', input]

useQueryTeamsPaginated.queryKey = (
  input: TGetTeamsPaginatedResponse
): QueryKey => ['teams_paginated', input]

export const useQueryTeam = (
  input: TGetTeamsParams,
  options?: UseQueryOptions<TGetTeamResponse>
) => {
  const queryFunction = () => getTeam(input)

  return useQuery<TGetTeamResponse>(
    useQueryTeam.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeam.getFullQueryKey = (): QueryKey => ['Team']

useQueryTeam.queryKey = ['Team']

export const useQueryMemberRoles = (
  options?: UseQueryOptions<TGetMembersRolesResponse>
) => {
  const queryFunction = () => getMemberRoles()

  return useQuery<TGetMembersRolesResponse>(
    useQueryMemberRoles.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryMemberRoles.getFullQueryKey = (): QueryKey => ['roles']

useQueryMemberRoles.queryKey = ['roles']

export const useQueryUserProfiles = (
  options?: UseQueryOptions<TGetUserProfileResponse>
) => {
  const queryFunction = () => getUserProfiles()

  return useQuery<TGetUserProfileResponse>(
    useQueryUserProfiles.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUserProfiles.getFullQueryKey = (): QueryKey => ['profiles']

useQueryUserProfiles.queryKey = ['profiles']

export const useQuerySupervisors = (
  supervisorIds: number[],
  options?: UseQueryOptions<TGetSupervisorResponse>
) => {
  const supervisorsQueries = useQueries({
    queries: supervisorIds?.map(coUser => ({
      queryKey: useQuerySupervisors.getFullQueryKey(coUser),
      queryFn: () => getSupervisors({ coUser }),
      enabled: !!coUser,
      options,
    })),
  })

  return supervisorsQueries.map(query => query.data)
}
useQuerySupervisors.getFullQueryKey = (coUser: number): QueryKey => [
  'supervisors',
]

useQuerySupervisors.queryKey = ['supervisors']

export const useQueryVolume = (
  input: TGetVolumeParams,
  options?: UseQueryOptions<TGetVolumesResponse>
) => {
  const queryFunction = () => getVolumeDistributions(input)

  return useQuery<TGetVolumesResponse>(
    useQueryVolume.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryVolume.getFullQueryKey = (input: TGetVolumeParams): QueryKey => [
  'volumes',
  input,
]

useQueryVolume.queryKey = (input: TGetVolumeParams): QueryKey => [
  'volumes',
  input,
]

export const useQueryVolumesPaginated = (
  input: TGetVolumesPaginatedParams,
  options?: UseQueryOptions<TGetVolumesPaginatedResponse>
) => {
  const queryFunction = () => getVolumesPaginated(input)

  return useQuery<TGetVolumesPaginatedResponse>(
    useQueryTeamsPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryVolumesPaginated.getFullQueryKey = (
  input: TGetVolumesPaginatedResponse
): QueryKey => ['volumes_paginated', input]

useQueryVolumesPaginated.queryKey = (
  input: TGetVolumesPaginatedResponse
): QueryKey => ['volumes_paginated', input]

import React, { useRef, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa6'
import { IoIosCloseCircle, IoIosSave } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import Tabs from '@/components/Tabs'
import StatusTag from '@/components/Tag/StatusQuestions'

import DeleteQuestionModal from './parts/DeleteQuestionModal'
import AnswersTab from './parts/Tabs/AnswersTab'
import FormsTab from './parts/Tabs/FormsTab'
import { ITabItemQuestionsDetails, TQuestionDetailsFormValues } from './types'

const QuestionDetails: React.FC = () => {
  const [isActiveQuestion, setIsActiveQuestion] = useState(false)
  const navigate = useNavigate()

  const formikRef = useRef<FormikProps<TQuestionDetailsFormValues>>(null)

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório'),
    formatVolume: yup.string().required('O formato é obrigatório'),
    versãoVolume: yup.string().required('A versão é obrigatória'),
    exemplaryVolume: yup.string().required('O exemplar é obrigatório'),
    diligence: yup.string().required('A diligência é obrigatória'),
    typeQuestion: yup.string().required('O tipo é obrigatório'),
  })

  const onSubmit = (values: TQuestionDetailsFormValues) => {
    const payload = {
      title: values.title,
      formatVolume: values.formatVolume,
      versionVolume: values.versionVolume,
      exemplaryVolume: values.exemplaryVolume,
      diligence: values.diligence,
      typeQuestion: values.typeQuestion,
    }
  }

  const {
    isOpen: isDeleteQuestionOpen,
    onOpen: onDeleteQuestionOpen,
    onClose: onDeleteQuestionClose,
  } = useDisclosure()

  const handleOpenRegisterFailModal = () => {
    onDeleteQuestionOpen()
  }

  const questionsTabs: Array<ITabItemQuestionsDetails> = [
    {
      id: 'questions',
      label: 'Respostas',
      render: () => <AnswersTab />,
    },
    {
      id: 'forms',
      label: 'Formulários',
      render: () => <FormsTab />,
    },
  ]

  const optionsInputSelect = [
    { value: 'option1', label: 'Opção 1' },
    { value: 'option2', label: 'Opção 2' },
    { value: 'option3', label: 'Opção 3' },
  ]

  return (
    <>
      <DeleteQuestionModal
        isOpen={isDeleteQuestionOpen}
        onClose={onDeleteQuestionClose}
      />

      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Box>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color="brand.primary.dark_1"
              mb={2}
            >
              ID DA PERGUNTA
            </Text>
            <StatusTag tag={isActiveQuestion ? 'active' : 'inactive'} />
          </Box>
          <Flex align="center">
            <FormLabel fontSize="sm" mb={1}>
              {isActiveQuestion ? 'Desativar pergunta' : 'Ativar pergunta'}
            </FormLabel>
            <Switch
              size="md"
              onChange={() => setIsActiveQuestion(!isActiveQuestion ?? false)}
              sx={{
                '& .chakra-switch__track[data-checked]': {
                  backgroundColor: 'brand.primary.dark_1',
                },
              }}
            />
          </Flex>
        </Flex>
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            title: '',
            formatVolume: '',
            versionVolume: '',
            exemplaryVolume: '',
            diligence: '',
            typeQuestion: '',
          }}
        >
          <Form>
            <Stack gap={4} marginTop={4}>
              <Input
                label="Título da pergunta"
                name="title"
                placeholder="---"
              />
              <Flex
                gap="4"
                flexDirection={{
                  base: 'column',
                  md: 'row',
                  xl: 'row',
                }}
              >
                <Select
                  options={optionsInputSelect}
                  label="Formato do volume"
                  name="formatVolume"
                  placeholder="---"
                />
                <Select
                  options={optionsInputSelect}
                  label="Versão do volume"
                  name="versionVolume"
                  placeholder="---"
                />
                <Select
                  options={optionsInputSelect}
                  label="Exemplar do volume"
                  name="exemplaryVolume"
                  placeholder="---"
                />
              </Flex>
              <Flex
                direction="row"
                gap="4"
                flexDirection={{
                  base: 'column',
                  md: 'row',
                  xl: 'row',
                }}
              >
                <Select
                  options={optionsInputSelect}
                  label="Diligência"
                  name="diligence"
                  placeholder="---"
                />
                <Select
                  options={optionsInputSelect}
                  label="Tipo de resposta"
                  name="typeQuestion"
                  placeholder="---"
                />
              </Flex>
            </Stack>
          </Form>
        </Formik>
      </Stack>

      <Stack p={4} boxShadow="md" borderRadius="md" pt={6}>
        <Tabs variant="rounded" items={questionsTabs} initialTab="principal" />
        <Flex justify="space-between" direction={['column', 'row']}>
          <Button
            size="sm"
            key="go-back"
            variant="ghost"
            leftIcon={<FaChevronLeft />}
            color="brand.primary.dark_1"
            onClick={() => navigate('/formularios')}
          >
            Voltar
          </Button>
          <Flex
            alignItems="center"
            direction={['column', 'row']}
            gap={6}
            mt={{ base: 6, md: 0, xl: 0 }}
          >
            <Button
              leftIcon={<IoIosCloseCircle size="16px" />}
              size="sm"
              backgroundColor="brand.error.base"
              _hover={{ bg: 'brand.error.dark' }}
              onClick={handleOpenRegisterFailModal}
            >
              Excluir pergunta
            </Button>
            <Button size="sm" leftIcon={<IoIosSave size="16px" />}>
              Salvar alterações
            </Button>
          </Flex>
        </Flex>
      </Stack>
    </>
  )
}

export default QuestionDetails

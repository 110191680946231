import React, { useRef } from 'react'

import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { Button, Flex, Stack } from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import Modal from '@/components/Modal'

import S from './styles'
import {
  IRegisterQuestionModalProps,
  TRegisterQuestionFormValues,
} from './types'

const RegisterQuestionModal: React.FC<IRegisterQuestionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const formikRef = useRef<FormikProps<TRegisterQuestionFormValues>>(null)

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório'),
    formatVolume: yup.string().required('O formato é obrigatório'),
    versãoVolume: yup.string().required('A versão é obrigatória'),
    exemplaryVolume: yup.string().required('O exemplar é obrigatório'),
    codeFail: yup.string().required('O código da falha é obrigatório'),
    typeQuestion: yup.string().required('O tipo é obrigatório'),
    showPages: yup.string().required('Selecione uma opção'),
    description: yup.string().required('A descrição é obrigatória'),
  })

  const onSubmit = (values: TRegisterQuestionFormValues) => {
    const payload = {
      title: values.title,
      formatVolume: values.formatVolume,
      versionVolume: values.versionVolume,
      exemplaryVolume: values.exemplaryVolume,
      codeFail: values.codeFail,
      typeQuestion: values.typeQuestion,
      showPages: values.showPages,
      description: values.description,
    }
  }

  const optionsInputSelect = [
    { value: 'option1', label: 'Opção 1' },
    { value: 'option2', label: 'Opção 2' },
    { value: 'option3', label: 'Opção 3' },
  ]

  const optionsInputSelectShowPages = [
    { value: 'yes', label: 'Sim' },
    { value: 'not', label: 'Não' },
  ]

  const handleRegisterQuestion = () => {
    if (formikRef.current) {
      formikRef.current.validateForm().then(errors => {
        if (Object.keys(errors).length === 0) {
          formikRef.current && formikRef.current.handleSubmit()
          onClose()
        }
      })
    }
  }

  return (
    <Modal
      title="Cadastrar nova pergunta"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        sm: 'sm',
        md: '3xl',
        xl: '4xl',
      }}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          title: '',
          formatVolume: '',
          versionVolume: '',
          exemplaryVolume: '',
          codeFail: '',
          typeQuestion: '',
          showPages: false,
          description: '',
        }}
      >
        <Form>
          <Stack gap={4}>
            <Input label="Título da pergunta" name="title" placeholder="---" />
            <Flex
              gap="4"
              flexDirection={{
                base: 'column',
                md: 'row',
                xl: 'row',
              }}
            >
              <Select
                options={optionsInputSelect}
                label="Formato do volume"
                name="formatVolume"
                placeholder="---"
              />
              <Select
                options={optionsInputSelect}
                label="Versão do volume"
                name="versionVolume"
                placeholder="---"
              />
              <Select
                options={optionsInputSelect}
                label="Exemplar do volume"
                name="exemplaryVolume"
                placeholder="---"
              />
            </Flex>
            <Flex
              direction="row"
              gap="4"
              flexDirection={{
                base: 'column',
                md: 'row',
                xl: 'row',
              }}
            >
              <Select
                options={optionsInputSelect}
                label="Código da falha"
                name="codeFail"
                placeholder="---"
              />
              <Select
                options={optionsInputSelect}
                label="Tipo de resposta"
                name="typeQuestion"
                placeholder="---"
              />
              <Select
                options={optionsInputSelectShowPages}
                label="Informar as páginas?"
                name="showPages"
                placeholder="---"
              />
            </Flex>
            <Input
              type="textarea"
              label="Descrição"
              name="description"
              placeholder="Descrição"
              helperText="Descreva aqui uma ajuda para o analista responder a pergunta"
              resize="none"
            />
          </Stack>
        </Form>
      </Formik>
      <S.ModalActionButtons>
        <Button
          key="go-back"
          variant="outline"
          size="sm"
          onClick={onClose}
          borderRadius={50}
        >
          Cancelar
        </Button>
        <Button
          key="confirm"
          size="sm"
          bg="brand.primary.dark_1"
          color="white"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          onClick={handleRegisterQuestion}
          borderRadius={50}
        >
          Cadastrar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default RegisterQuestionModal

import React from 'react'

import { Button, Flex, useDisclosure } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import { ModalExistingQuestion } from '../ExistingQuestionModal'
import { ModalNewQuestion } from '../NewQuestionModal'
import { IModalProps } from '../types'

export const ModalQuestion = ({
  isOpen,
  onClose,
}: IModalProps): JSX.Element => {
  const newQuestionDisclosure = useDisclosure()
  const existingQuestionDisclosure = useDisclosure()

  return (
    <Modal
      title="Adicionar pergunta existente"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Flex
        justifyContent="center"
        gap="12px"
        alignItems="center"
        flexDirection="row"
      >
        <Button
          variant="outline"
          borderRadius="full"
          onClick={newQuestionDisclosure.onOpen}
        >
          Adicionar nova pergunta
        </Button>
        <Button borderRadius="full" onClick={existingQuestionDisclosure.onOpen}>
          Adicionar pergunta existente
        </Button>
      </Flex>
      <ModalNewQuestion
        isOpen={newQuestionDisclosure.isOpen}
        onClose={() => {
          newQuestionDisclosure.onClose()
          onClose()
        }}
      />
      <ModalExistingQuestion
        isOpen={existingQuestionDisclosure.isOpen}
        onClose={() => {
          existingQuestionDisclosure.onClose()
          onClose()
        }}
      />
    </Modal>
  )
}

import React, { useState } from 'react'
import { FaChevronLeft, FaCheckCircle } from 'react-icons/fa'

import { Formik, Form, FormikValues } from 'formik'
import * as yup from 'yup'

import { Flex, Box, Button, Skeleton, SkeletonText } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import { ProfileProvider } from '@/contexts/ProfileContext/ProfileContext'
import BondsForm from '@/pages/SystemSettings/User/Details/BondsForm'
import UserInfoForm from '@/pages/SystemSettings/User/Details/UserInfoForm'

import { useUserDetailsController } from './controller'
import Permissions from './Permissions'
import S from './styles'
import Teams from './Teams'

const Details = () => {
  const controller = useUserDetailsController()
  const {
    userValues,
    userId,
    activeTabId,
    setActiveTabId,
    userData,
    isLoadingUserData,
    isAdmin,
    statusTagValue,
    handleSubmit,
    isUpdatingAccount,
  } = controller

  const validationSchema = yup.object().shape({
    dsInstitution: yup.string().required('A instituição é obrigatória'),
    dsInstitutionalEmail: yup
      .string()
      .required('O email institucional é obrigatório'),
    dsRolePositionJob: yup.string().required('O cargo é obrigatório'),
    dtJobBindStart: yup.date().required('A data de início é obrigatória'),
  })

  const tabs: Array<ITabItem> = [
    {
      id: 'informacoes-pessoais',
      label: 'Informações pessoais',
      render: () => {
        return userId && <UserInfoForm userId={userId} />
      },
    },
    // {
    //   id: 'permissoes',
    //   label: 'Permissões',
    //   render: () => <Permissions />,
    // },
    {
      id: 'vinculos',
      label: 'Vínculos',
      render: () => {
        return userId && <BondsForm userId={userId} />
      },
    },
    {
      id: 'equipes',
      label: 'Equipes',
      render: () => <Teams />,
    },
  ]

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <S.TopContainer>
          <S.AvatarContainer>
            <S.StyledAvatar src={userValues?.dsPhotoUrl} />
          </S.AvatarContainer>
          <S.UserInfo>
            <SkeletonText isLoaded={!isLoadingUserData} noOfLines={2}>
              <S.UserNameText>
                {userData?.noUserName || 'Nome do usuário'}
              </S.UserNameText>
              <S.UserEmailText>
                {userData?.dsGovbrEmail || 'Email do usuário'}
              </S.UserEmailText>
            </SkeletonText>
            <Skeleton isLoaded={!isLoadingUserData}>
              <Tag group="statusUserSystem" value={statusTagValue} />
            </Skeleton>
          </S.UserInfo>
        </S.TopContainer>
        <ProfileProvider value={controller}>
          <Formik
            enableReinitialize
            initialValues={userValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box>
                <Tabs
                  items={tabs}
                  initialTab={activeTabId}
                  onTabClick={({ id }: ITabInfo) => setActiveTabId(id)}
                />
                <Flex
                  direction="row"
                  justifyContent="flex-end"
                  gap="2"
                  marginTop="10"
                >
                  <Button
                    size="sm"
                    leftIcon={<FaChevronLeft />}
                    variant="ghost"
                    isDisabled={isUpdatingAccount}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    leftIcon={<FaCheckCircle />}
                    isLoading={isUpdatingAccount}
                  >
                    Salvar alterações
                  </Button>
                </Flex>
              </Box>
            </Form>
          </Formik>
        </ProfileProvider>
      </Flex>
    </Flex>
  )
}

export default Details

import React, { useMemo, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import {
  FaCheckCircle,
  FaPlusCircle,
  FaSave,
  FaTimesCircle,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Form, Formik } from 'formik'

import {
  Flex,
  Button,
  Text,
  Stack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

import { Select } from '@/components/Forms'
import Switch from '@/components/Switch'
import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import StatusTag from '@/components/Tag/StatusChecklist'

import { ModalQuestion } from './AddQuestionModal'
import { ModalApproveForm } from './ApproveFormModal'
import { ModalDeleteForm } from './DeleteFormModal'
import { QuestionsList } from './QuestionsList'
import { IChecklistStatusMapping } from './types'
import Versions from './Versions'

const ChecklistDetails = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState('Aguardando')
  const [approved, setApproved] = useState(false)
  const [linked, setLinked] = useState(false)

  const checklistStatus = useMemo(() => {
    return IChecklistStatusMapping[status]
  }, [status])

  const {
    isOpen: isModalQuestionOpen,
    onOpen: onModalQuestionOpen,
    onClose: onCloseQuestionModal,
  } = useDisclosure()
  const {
    isOpen: isModalDeleteOpen,
    onOpen: onModalDeleteOpen,
    onClose: onCloseDeleteModal,
  } = useDisclosure()
  const {
    isOpen: isModalApproveOpen,
    onOpen: onModalApproveOpen,
    onClose: onCloseApproveModal,
  } = useDisclosure()

  const detailsTabs: Array<ITabItem> = [
    {
      id: 'questions',
      label: 'Perguntas',
      render: () => <QuestionsList />,
    },
    {
      id: 'version',
      label: 'Versões',
      render: () => <Versions />,
    },
  ]

  const objectsOptions = [
    {
      value: '1',
      label: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '2',
      label: 'Objeto 02: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '3',
      label: 'Objeto 03: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '4',
      label: 'Objeto 04: Obras Didáticas - Impresso e Digital-Interativo',
    },
  ]

  const phaseOptions = [
    {
      value: '1',
      label: 'Em Andamento',
    },
    {
      value: '2',
      label: 'Concluído',
    },
  ]

  const failureGroupOptions = [
    {
      value: '1',
      label: 'Grupo 01',
    },
    {
      value: '2',
      label: 'Grupo 02',
    },
  ]

  const volumeFormatOptions = [
    {
      value: '1',
      label: 'Formato 01',
    },
    {
      value: '2',
      label: 'Formato 02',
    },
  ]

  const volumeVersionOptions = [
    {
      value: '1',
      label: 'Versão 01',
    },
    {
      value: '2',
      label: 'Versão 02',
    },
  ]

  const volumeExamepleOptions = [
    {
      value: '1',
      label: 'Exemplar 01',
    },
    {
      value: '2',
      label: 'Exemplar 02',
    },
  ]

  const handleAddQuestion = () => {
    onModalQuestionOpen()
  }
  const handleDeleteQuestion = () => {
    onModalDeleteOpen()
  }
  const handleApproveForm = () => {
    onModalApproveOpen()
  }
  const handleApproved = () => {
    setApproved(true)
    onCloseApproveModal()
  }
  const handleSwitch = () => {
    setLinked(!linked)
    setStatus(status === 'Aguardando' ? 'Vinculado' : 'Aguardando')
  }

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex direction="row" justifyContent="space-between">
          <Flex justify="space-between" direction="column" gap={4}>
            <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
              FORM0001
            </Text>
            <Tag group="statusChecklist" value={checklistStatus} />
          </Flex>
          <Flex direction="row" alignItems="center" gap={8}>
            <Switch
              disabled={!approved}
              label="Ativar formulário"
              onActivate={handleSwitch}
            />
            <Button
              size="sm"
              variant={!approved ? 'success' : 'disabled'}
              disabled={approved}
              leftIcon={<FaCheckCircle />}
              onClick={handleApproveForm}
              alignItems="center"
            >
              Aprovar versão
            </Button>
          </Flex>
        </Flex>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <Flex gap={4}>
              <Select
                name="coObjects"
                label="Objeto"
                placeholder="Selecione uma opção"
                options={objectsOptions}
              />
              <Select
                name="coPhase"
                label="Fase"
                placeholder="Selecione uma opção"
                options={phaseOptions}
              />
              <Select
                name="coFailureGroup"
                label="Grupo de falha"
                placeholder="Selecione uma opção"
                options={failureGroupOptions}
              />
            </Flex>
            <Flex gap={4}>
              <Select
                name="coVolumeFormat"
                label="Formato do Volume"
                placeholder="Selecione uma opção"
                options={volumeFormatOptions}
              />
              <Select
                name="coVolumeVersion"
                label="Versao do Volume"
                placeholder="Selecione uma opção"
                options={volumeVersionOptions}
              />
              <Select
                name="coVolumeExameple"
                label="Exemplar do Volume"
                placeholder="Selecione uma opção"
                options={volumeExamepleOptions}
              />
            </Flex>
          </Form>
        </Formik>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Tabs variant="rounded" items={detailsTabs} initialTab="principal" />
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          padding="5"
          gap="8"
        >
          <Button
            size="sm"
            leftIcon={<BsChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
            onClick={() => navigate('/formularios/')}
          >
            Voltar
          </Button>
          <Flex gap={2}>
            <Button
              variant={!linked ? 'error' : 'disabled'}
              leftIcon={<FaTimesCircle />}
              onClick={handleDeleteQuestion}
              disabled={!linked}
            >
              Excluir formulário
            </Button>
            <Button
              variant="outline"
              leftIcon={<FaPlusCircle />}
              onClick={handleAddQuestion}
            >
              Adicionar Pergunta
            </Button>
            <Button leftIcon={<FaSave />} variant="solid">
              Salvar alterações
            </Button>
          </Flex>
        </Flex>
      </Stack>
      <ModalQuestion
        isOpen={isModalQuestionOpen}
        onClose={onCloseQuestionModal}
      />
      <ModalDeleteForm
        isOpen={isModalDeleteOpen}
        onClose={onCloseDeleteModal}
      />
      <ModalApproveForm
        isOpen={isModalApproveOpen}
        onClose={onCloseApproveModal}
        handleApprove={handleApproved}
      />
    </>
  )
}

export default ChecklistDetails

import React from 'react'
import { FaCircleExclamation } from 'react-icons/fa6'

import { Flex, Icon, Text } from '@chakra-ui/react'

const ContentEmpityTab: React.FC = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      minH={300}
    >
      <Icon
        as={FaCircleExclamation}
        boxSize={{
          base: '48px',
          md: '64px',
          xl: '128px',
        }}
        color="brand.primary.dark_1"
        mb={8}
      />
      <Text
        fontSize={{
          base: 'sm',
          md: 'md',
          xl: 'xl',
        }}
        fontWeight="bold"
        color="brand.primary.dark_1"
        textAlign="center"
        maxW={400}
      >
        Essa pergunta ainda não foi utilizada em formulários.
      </Text>
    </Flex>
  )
}

export default ContentEmpityTab

import { ReactNode } from 'react'

import { status } from '@/components/Tag/StatusChecklist/types'

export interface IQuestionItem {
  id: string
  label: string
  render?: () => ReactNode
}

export interface IQuestionsGroupData {
  id: string
  name: string
  description: string
  type: string
  dsStatus: string
}

export interface IQuestionsListProps {
  questions: Array<IQuestionsGroupData>
  handleDeleteQuestion: (id: string) => void
}

export interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

export const IChecklistStatusMapping: Record<string, status> = {
  Vinculado: 'active',
  Aguardando: 'on_hold',
  Finalizado: 'finished',
}

import React, { useMemo, useState } from 'react'
import { FaMinusCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { EditIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Flex,
  Button,
  IconButton,
  Text,
  useDisclosure,
  VStack,
  Box,
  useToast,
} from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import { useQueryTeamsPaginated } from '@/api/teams/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tabs from '@/components/Tabs'
import { ITabInfo } from '@/components/Tabs/types'
import StatusTag from '@/components/Tag/StatusCardTeam'
import { reverseDate } from '@/utils/date'

import { filterExample, tableHeaders } from './exampleData'
import DeleteTeamModal from './parts/DeleteTeamModal'
import { ITeamStatusMapping } from './types'

const filters: Array<IFilter> = [
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        value: 'ativa',
        label: 'Ativa',
      },
      {
        value: 'Inativo',
        label: 'Inativo',
      },
      {
        value: 'Concluido',
        label: 'Concluído',
      },
      {
        value: 'Esperando_Objetos',
        label: 'Esperando Objetos',
      },
      {
        value: 'ESPERANDO_MEMBROS',
        label: 'Esperando Membros',
      },
      {
        value: 'PENDENTE',
        label: 'Pendente',
      },
      {
        value: 'ESPERANDO_OBRAS',
        label: 'Esperando Obras',
      },
    ],
  },
]

const Team = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const initialPath = location.pathname.split('/').pop()

  const {
    isOpen: isDeleteTeamOpen,
    onOpen: onDeleteTeamOpen,
    onClose: onDeleteTeam,
  } = useDisclosure()
  const [selectedTeams, setSelectedTeams] = useState<Array<RowData>>([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })
  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    status: '',
  })

  const {
    data: teamsData = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 10,
      },
      results: [],
    },
    isFetching: isFetchingTeamsData,
    refetch: refetchTeamsData,
  } = useQueryTeamsPaginated(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      noTeam: filter.search,
      initialDate: filter.startDate,
      finalDate: filter.endDate,
      stTeamStatus: filter.status,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage || 1,
          pageSize: data.pagination.pageSize || 10,
          itemsCount: data.pagination.count || 0,
          pageCount: data.pagination.totalPages || 1,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao buscar equipes',
          description: 'Tente novamente mais tarde',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const handleDeleteTeam = () => {
    onDeleteTeamOpen()
  }

  const handleTabClick = (id: string) => {
    navigate(`/configuracoes/${id}`)
  }

  const tabs = [
    {
      id: 'usuario',
      label: 'Usuário',
    },
    {
      id: 'equipe',
      label: 'Equipe',
    },
    // {
    //   id: 'permissoes',
    //   label: 'Permissões',
    // },
  ]

  const teamsDataTable = useMemo(() => {
    const teams = teamsData?.results || []

    const mappedTeams = teams.map(team => {
      const teamInfo = (title: string, description: number) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {title}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {description}
            </Text>
          </Box>
        </VStack>
      )

      const supervisorInfo = (
        name: string | undefined,
        email: string | undefined
      ) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {name}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {email}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: number) => {
        const handleEdit = () => {
          navigate(`/configuracoes/equipe/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      const statusTagValue = ITeamStatusMapping[team.stTeamStatus]

      return {
        id: team.coTeam,
        user: teamInfo(team.noTeam, team.coTeam),
        date: team.dtCreatedAt,
        supervisor: supervisorInfo(
          team.coSupervisor.noUserName,
          team.coSupervisor.dsGovbrEmail
        ),
        status: StatusTag({ tag: statusTagValue }),
        edit: editButton(team.coTeam),
        status_code: team.stTeamStatus,
      }
    })
    return mappedTeams
  }, [teamsData])

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      status: filters.status || '',
    })
  }

  return (
    <>
      <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => {
          handleTabClick(id)
        }}
      />
      <Flex direction="column" gap={6}>
        <Box p={4} boxShadow="md">
          <DataFilter
            testId="teams-filter"
            filters={filters}
            onChangeFilter={handleFilters}
          />
        </Box>
        <Flex
          width="full"
          p={5}
          borderRadius="lg"
          borderWidth={1}
          boxShadow="md"
          flexDirection={'column'}
          gap={4}
          bg="white"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize="xl"
              fontWeight="700"
              color="brand.primary.dark_1"
              lineHeight="26px"
            >
              Configurações de Equipes
            </Text>
            <Button
              leftIcon={<FaMinusCircle size="14px" color="white" />}
              size="sm"
              fontSize="sm"
              fontWeight="semibold"
              backgroundColor="brand.error.base"
              color="white"
              _hover={{ bg: 'brand.error.dark' }}
              onClick={handleDeleteTeam}
            >
              Excluir equipes
            </Button>
          </Flex>
          <DataTable
            key={'team-data-table'}
            headers={tableHeaders}
            data={teamsDataTable}
            selectableRow
            onRowSelectionChange={rows => setSelectedTeams(rows)}
            isLoading={isFetchingTeamsData}
          />
          <Pagination
            pageIndex={pagination.page - 1}
            optionsItemsPerPage={pagination.pageSizeOptions}
            itemsPerPage={pagination.pageSize}
            pageCount={pagination.pageCount}
            itemsCount={pagination.itemsCount}
            onChangePageInfo={handleOnChangePageInfo}
          />
        </Flex>
      </Flex>
      <DeleteTeamModal
        teams={selectedTeams}
        isOpen={isDeleteTeamOpen}
        onClose={onDeleteTeam}
      />
    </>
  )
}

export default Team

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Text } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import S from './styles'
import { IDeleteQuestionModalProps } from './types'

const DeleteQuestionModal: React.FC<IDeleteQuestionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()

  return (
    <Modal
      title="Excluir pergunta"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        md: 'xl',
      }}
    >
      <Text fontSize="sm" fontWeight="normal" color="brand.primary.dark_1">
        <b>Esta ação é irreversível! </b>Tem certeza que deseja excluir essa
        pergunta permanentemente de todos os formulários vinculados?
      </Text>
      <S.ModalActionButtons>
        <Button
          key="delete"
          size="sm"
          backgroundColor="brand.error.base"
          _hover={{ bg: 'brand.error.dark' }}
          onClick={() => {
            onClose()
            navigate('/formularios')
          }}
          borderRadius={50}
        >
          Excluir pergunta
        </Button>
        <Button
          key="cancel"
          variant="outline"
          size="sm"
          borderRadius={50}
          onClick={onClose}
        >
          Cancelar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default DeleteQuestionModal

import React from 'react'

import { Form, Formik } from 'formik'

import { Text, Button, Flex, Stack, useDisclosure, Box } from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import Modal from '@/components/Modal'

import { IModalProps } from '../types'

export const ModalNewQuestion = ({
  isOpen,
  onClose,
}: IModalProps): JSX.Element => {
  const objectsOptions = [
    {
      value: '1',
      label: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '2',
      label: 'Objeto 02: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '3',
      label: 'Objeto 03: Obras Didáticas - Impresso e Digital-Interativo',
    },
    {
      value: '4',
      label: 'Objeto 04: Obras Didáticas - Impresso e Digital-Interativo',
    },
  ]

  const phaseOptions = [
    {
      value: '1',
      label: 'Em Andamento',
    },
    {
      value: '2',
      label: 'Concluído',
    },
  ]

  const failureGroupOptions = [
    {
      value: '1',
      label: 'Sim',
    },
    {
      value: '2',
      label: 'Não',
    },
  ]

  const volumeFormatOptions = [
    {
      value: '1',
      label: 'Formato 01',
    },
    {
      value: '2',
      label: 'Formato 02',
    },
  ]

  const volumeVersionOptions = [
    {
      value: '1',
      label: 'Versão 01',
    },
    {
      value: '2',
      label: 'Versão 02',
    },
  ]

  const volumeExamepleOptions = [
    {
      value: '1',
      label: 'Exemplar 01',
    },
    {
      value: '2',
      label: 'Exemplar 02',
    },
  ]

  const handleAddQuestion = () => {
    // TODO: Implementar a lógica de adicionar pergunta
    onClose()
  }

  return (
    <Modal
      title="Adicionar nova pergunta"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <Input
              placeholder="--"
              name="coQuestion"
              label="Título da pergunta"
            />
            <Flex gap={4}>
              <Select
                name="coVolumeFormat"
                label="Formato do Volume"
                placeholder="Placeholder"
                options={volumeFormatOptions}
              />
              <Select
                name="coVolumeVersion"
                label="Versao do Volume"
                placeholder="Placeholder"
                options={volumeVersionOptions}
              />
              <Select
                name="coVolumeExameple"
                label="Exemplar do Volume"
                placeholder="Placeholder"
                options={volumeExamepleOptions}
              />
            </Flex>
            <Flex gap={4}>
              <Select
                name="coFalha"
                label="Código da Falha"
                placeholder="--"
                options={objectsOptions}
              />
              <Select
                name="coResponseType"
                label="Tipo de Resposta"
                placeholder="--"
                options={phaseOptions}
              />
              <Select
                name="coInform"
                label="Informar as falhas?"
                placeholder={failureGroupOptions[0].label}
                options={failureGroupOptions}
              />
            </Flex>
          </Form>
        </Formik>
        <Flex
          justifyContent="space-between"
          gap="12px"
          alignItems="left"
          flexDirection="column"
        >
          <Text fontSize="sm" fontWeight="bold" color="brand.primary.dark_2">
            Descrição
          </Text>
          <Box
            borderRadius="8px"
            padding={4}
            backgroundColor="gray.100"
            alignItems="left"
          >
            <Text fontSize="sm" fontStyle="italic" color="brand.primary.dark_1">
              Mussum Ipsum, cacilds vidis litro abertis. Não sou faixa preta
              cumpadi, sou preto inteiris, inteiris. A ordem dos tratores não
              altera o pão duris. Nec orci ornare consequat. Praesent lacinia
              ultrices consectetur. Sed non ipsum felis. Praesent vel viverra
              nisi. Mauris aliquet nunc non turpis scelerisque, eget.{' '}
            </Text>
          </Box>
          <Text fontSize="sm" color="brand.primary.dark_1">
            Descreva aqui uma ajuda para o analistar responder a pergunta.
          </Text>
          <Flex
            justifyContent="right"
            gap="16px"
            alignItems="center"
            flexDirection="row"
          >
            <Button variant="outline" borderRadius="full" onClick={onClose}>
              Cancelar
            </Button>
            <Button borderRadius="full" onClick={handleAddQuestion}>
              Adicionar
            </Button>
          </Flex>
        </Flex>
      </Stack>
    </Modal>
  )
}

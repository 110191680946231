import React from 'react'
import { BsCircleHalf } from 'react-icons/bs'
import { Outlet } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'

import {
  Stack,
  Link,
  Image,
  Button,
  Flex,
  Hide,
  useColorModeValue,
} from '@chakra-ui/react'
import { useColorMode } from '@chakra-ui/react'

import LogoFNDE from '@/assets/img/fnde/logo/logo-fnde-b-nd-m.svg'
import LogoMEC from '@/assets/img/mec/logo/logo-mec-b-nd-m.svg'
import LogoNEES from '@/assets/img/nees/logo/logo-nees-b-nd-m-B.svg'
import ColorfulLogoSARE from '@/assets/img/sare/logo/16px/horizontal/logo-sare-16px-h-a-d-nm-RL.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/16px/horizontal/logo-sare-16px-h-b-nd-m-BB.svg'

import S from './styles'

function TemplateLogin() {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Stack minHeight="100vh">
      <S.Header bg={useColorModeValue('white', 'black')}>
        <S.HeaderContent>
          <Link as={ReactRouterLink} to="/login">
            <Image
              height="36px"
              src={colorMode == 'light' ? ColorfulLogoSARE : WhiteLogoSARE}
              alt="Logo Sare"
            />
          </Link>
          {/* <Button
            color={useColorModeValue('brand.primary.dark_1', 'white')}
            variant="link"
            onClick={toggleColorMode}
          >
            <Flex gap={2} align="center">
              <BsCircleHalf />
              <Hide below="sm">Alto contraste</Hide>
            </Flex>
          </Button> */}
        </S.HeaderContent>
      </S.Header>
      <S.Main>
        <Outlet />
      </S.Main>
      <S.Footer>
        <Image
          height={{ base: '2.625rem', sm: '4.5rem' }}
          src={LogoNEES}
          alt="Logo NEES"
        />
        <Image
          height={{ base: '2.25rem', sm: '3.75rem' }}
          src={LogoMEC}
          alt="Logo MEC"
        />
        <Image
          height={{ base: '1.625rem', sm: '2.375rem' }}
          src={LogoFNDE}
          alt="Logo FNDE"
        />
      </S.Footer>
    </Stack>
  )
}

export default TemplateLogin

import React, { useRef, useState } from 'react'
import { FaTrashCan } from 'react-icons/fa6'

import { Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'

import { Input } from '@/components/Forms'

import { TAnswerDetailsFormValues } from './types'

const optionsRadio = [
  {
    value: 'positive',
    label: 'Resposta positiva',
  },
  {
    value: 'negative',
    label: 'Resposta negativa',
  },
]

const optionsCheckboxOpenAnswer = [
  {
    value: 'answerDiligence',
    label: 'Essa resposta gera diligência?',
  },
  {
    value: 'hasAttachment',
    label: 'Tem anexo?',
  },
  {
    value: 'mandatoryAttachment',
    label: 'O Anexo é obrigatório',
  },
]

const AnswersTab: React.FC = () => {
  const [answer, setAnswer] = useState(true)
  const [checkedItems, setCheckedItems] = useState(
    new Array(optionsCheckboxOpenAnswer.length).fill(false)
  )

  const formikRef = useRef<FormikProps<TAnswerDetailsFormValues>>(null)

  const handleChangeCheckbox = (
    e: { target: { checked: boolean } },
    index: number
  ) => {
    const updatedCheckedItems = [...checkedItems]
    updatedCheckedItems[index] = e.target.checked
    setCheckedItems(updatedCheckedItems)
  }

  const validationSchema = yup.object({
    answer: yup.string().required('A resposta é obrigatória'),
  })

  const onSubmit = (values: TAnswerDetailsFormValues) => {
    const payload = {
      answer: values.answer,
    }
  }

  return (
    <Stack minH={300}>
      <Box mt={6}>
        <Text fontSize="md" fontWeight="bold">
          Qual das respostas gera diligência?
        </Text>
        <RadioGroup name="scope" size="lg" mt={4} mb={8}>
          <Stack spacing={[1, 12]} direction={['column', 'row']}>
            {optionsRadio.map(option => (
              <Radio
                key={`scope-radio-${option.value}`}
                value={option.value}
                onChange={() => setAnswer(!answer ?? true)}
                _checked={{
                  bg: 'brand.primary.base',
                }}
              >
                <Text fontSize="sm">{option.label}</Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>

        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            answer: '',
          }}
        >
          <Form>
            <Stack>
              <Flex alignItems="center" justifyContent="space-between">
                <Text fontSize="md" fontWeight="bold">
                  Resposta 1
                </Text>
                <FaTrashCan size={16} color="#E60000" cursor="pointer" />
              </Flex>
              <Input label="Resposta" name="answer" placeholder="---" />
            </Stack>
          </Form>
        </Formik>

        <CheckboxGroup size="lg" colorScheme="white">
          <Stack spacing={[1, 12]} direction={['column', 'row']} mt={4}>
            {optionsCheckboxOpenAnswer.map((option, index) => (
              <Checkbox
                key={`scope-checkbox-${option.value}`}
                isChecked={checkedItems[index]}
                onChange={e => handleChangeCheckbox(e, index)}
                iconColor="brand.primary.base"
              >
                <Text fontSize="sm">{option.label}</Text>
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </Box>
    </Stack>
  )
}

export default AnswersTab

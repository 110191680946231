import React from 'react'

import { Button, Flex, Stack, Text } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import { ICustomApproveButtonProps } from './types'

export const ModalApproveForm = ({
  isOpen,
  onClose,
  handleApprove,
}: ICustomApproveButtonProps): JSX.Element => {
  return (
    <Modal
      title="Aprovar formulário"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Text fontSize="sm" color="brand.primary.dark_2">
          Tem certeza que deseja aprovar esse formulário e liberar para ser
          respondido?
        </Text>
        <Flex
          justifyContent="right"
          gap="12px"
          alignItems="center"
          flexDirection="row"
        >
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Cancelar
          </Button>
          <Button borderRadius="full" onClick={handleApprove}>
            Aprovar formulário
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
